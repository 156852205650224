import actionCreators from "./actionCreators";

export const initialState = {
    users: {
        data: [],
        count: 0,
        nextPage: null,
    },
    user: {
        id: null,
        lseId: null,
        firstName: null,
        firstNameLocked: false,
        lastName: null,
        lastNameLocked: false,
        email: null,
        emailLocked: false,
        photoUrl: null,
        photoFile: null,
        photoRemove: false,
        status: null,
    },
    currentFormType: null,
    errorRes: { error: "", errors: {} },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actionCreators.createListStudents.type:
            return {
                ...state,
                users: payload,
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateListStudents.type: {
            // merge payload data with current state
            const ids = state.users.data.map(user => user.id);
            payload.data = [
                ...state.users.data,
                ...payload.data.filter(user => ids.indexOf(user.id) === -1),
            ];
            return {
                ...state,
                users: payload,
                errorRes: initialState.errorRes,
            };
        }
        case actionCreators.clearListStudents.type:
            return {
                ...state,
                users: { ...initialState.users },
                errorRes: initialState.errorRes,
            };
        case actionCreators.updateOneStudent.type:
            return {
                ...state,
                user: { ...state.user, ...payload },
                users: {
                    ...state.users,
                    data: state.users.data.map(u => (u.id === payload.id ? payload : u)),
                },
                errorRes: initialState.errorRes,
            };
        case actionCreators.clearStudent.type:
            return {
                ...state,
                user: { ...initialState.user },
            };
        case actionCreators.errorResponse.type:
            return {
                ...state,
                errorRes: payload,
            };

        case actionCreators.updateNewStudentHash.type: {
            return {
                ...state,
                users: {
                    ...state.users,
                    data: state.users.data.map(u =>
                        u.id == payload.id
                            ? {
                                  ...u,
                                  interactiveLoginHash: payload.interactiveLoginHash,
                              }
                            : u
                    ),
                },
            };
        }

        default:
            return state;
    }
};
