import { of } from "rxjs";
import { mergeMap, catchError, switchMap, mapTo } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import actionCreators from "./actionCreators";
import errorHandler from "../../../common/services/ajaxErrorHandler";
import * as ajax from "../../../common/services/utils";
import { default as UIActionCreators } from "../../MainMenu/actionCreators";
import { push } from "connected-react-router";

const studentsStartLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.getListStudents.type,
            actionCreators.loadMoreStudents.type,
            actionCreators.getOneStudent.type,
            actionCreators.getNewStudentHash.type,
            actionCreators.putStudent.type,
            actionCreators.removeStudent.type
        ),
        mapTo(UIActionCreators.setLoading.create())
    );

const studentsClearLoadingEpic = action$ =>
    action$.pipe(
        ofType(
            actionCreators.createListStudents.type,
            actionCreators.updateListStudents.type,
            actionCreators.updateOneStudent.type,
            actionCreators.updateNewStudentHash.type,
            actionCreators.errorResponse.type
        ),
        mapTo(UIActionCreators.clearLoading.create())
    );

const getListStudentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getListStudents.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`admin/students/?${action.payload}`)).pipe(
                mergeMap(({ response }) => of(actionCreators.createListStudents.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const loadMoreStudentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.loadMoreStudents.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`admin/students/?${action.payload}`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateListStudents.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getOneStudentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getOneStudent.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`admin/students/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOneStudent.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const getNewStudentHashEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.getNewStudentHash.type),
        switchMap(action =>
            ajax.get(ajax.apiUrl(`admin/students/${action.payload.id}/`)).pipe(
                mergeMap(({ response }) =>
                    of(actionCreators.updateNewStudentHash.create(response))
                ),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const editStudentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.putStudent.type),
        switchMap(action =>
            ajax.put(ajax.apiUrl(`admin/students/${action.payload.id}/`), action.payload).pipe(
                mergeMap(({ response }) => of(actionCreators.pushListStudents.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const pushListStudentsEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.pushListStudents.type),
        mapTo(push("/users-and-audiences/lse-students"))
    );

const removeStudentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.removeStudent.type),
        switchMap(action =>
            ajax.post(ajax.apiUrl(`admin/students/${action.payload.id}/archive`)).pipe(
                mergeMap(({ response }) => of(actionCreators.pushListStudents.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

const upgradeStudentEpic = action$ =>
    action$.pipe(
        ofType(actionCreators.upgradeToStaff.type),
        switchMap(({ payload }) =>
            ajax.patch(ajax.apiUrl(`admin/students/${payload}/make-staff`)).pipe(
                mergeMap(({ response }) => of(actionCreators.updateOneStudent.create(response))),
                catchError(errorHandler(actionCreators.errorResponse.create))
            )
        )
    );

export const epics = combineEpics(
    studentsStartLoadingEpic,
    studentsClearLoadingEpic,
    getListStudentsEpic,
    loadMoreStudentsEpic,
    getOneStudentEpic,
    editStudentEpic,
    pushListStudentsEpic,
    removeStudentEpic,
    upgradeStudentEpic,
    getNewStudentHashEpic
);
