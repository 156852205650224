import ArrowUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import AvailabilityIcon from "@material-ui/icons/DateRange"; // office hours
import AutoRenewIcon from "@material-ui/icons/Autorenew";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import CommentIcon from "@material-ui/icons/ChatBubbleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/AddRounded";
import EventIcon from "@material-ui/icons/Event";
import FavoriteIcon from "@material-ui/icons/FavoriteBorder";
import GroupIcon from "@material-ui/icons/Group"; // dashboard
import HolidayIcon from "@material-ui/icons/BeachAccess"; // office hours
import MailIcon from "@material-ui/icons/MailOutline";
import LinkIcon from "@material-ui/icons/CallMade";
import LinkAltIcon from "@material-ui/icons/Link";
import OpenIcon from "@material-ui/icons/OpenInNew";
import PageIcon from "@material-ui/icons/FilterNone"; // dashboard
import PersonIcon from "@material-ui/icons/Person"; // dashboard
import PhoneIcon from "@material-ui/icons/Phone";
import PostIcon from "@material-ui/icons/EventNote";
import PreviewIcon from "@material-ui/icons/Launch";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import FileUploadIcon from "@material-ui/icons/CloudUpload";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import EventNoteIcon from "@material-ui/icons/EventNote";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import HistoryIcon from "@material-ui/icons/History";
import SyncIcon from "@material-ui/icons/Sync";
import MemberIcon from "@material-ui/icons/People";
import UpgradeIcon from "@material-ui/icons/TrendingUp";
import DowngradeIcon from "@material-ui/icons/TrendingDown";
import AcceptIcon from "@material-ui/icons/Check";
import DenyIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ScheduleIcon from "@material-ui/icons/Schedule";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import SettingsIcon from "@material-ui/icons/Settings";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import WarningIcon from "@material-ui/icons/WarningRounded";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

const MaskIcon = props => {
    return (
        <SvgIcon {...props}>
            <path d="M15.83 9.81C14.7 9.7 13.69 10.38 13.46 11.5C13.46 11.84 14.81 12.29 16.05 12.29C17.29 12.29 18.41 11.5 18.41 11.28C18.41 11.05 17.63 9.93 15.83 9.81M8.18 9.81C6.38 9.93 5.59 10.94 5.59 11.27C5.59 11.5 6.82 12.29 7.95 12.29S10.54 11.84 10.54 11.5C10.31 10.38 9.19 9.7 8.18 9.81M16.95 16C15.04 16 13.8 13.75 12 13.75S8.85 16 7.05 16C4.69 16 3 13.86 3 10.04C3 7.68 3.68 7 6.71 7S10.54 8.24 12 8.24 14.36 7 17.29 7 21 7.79 21 10.04C21 13.86 19.31 16 16.95 16Z"></path>
        </SvgIcon>
    );
};

export {
    ArrowDownIcon,
    ArrowUpIcon,
    AvailabilityIcon,
    AutoRenewIcon,
    CheckIcon,
    ClearIcon,
    CloseIcon,
    CommentIcon,
    DeleteIcon,
    DownloadIcon,
    EditIcon,
    EventIcon,
    FavoriteIcon,
    GroupIcon,
    HolidayIcon,
    LinkIcon,
    LinkAltIcon,
    MailIcon,
    OpenIcon,
    PageIcon,
    PersonIcon,
    PhoneIcon,
    PostIcon,
    PreviewIcon,
    LogoutIcon,
    FileUploadIcon,
    PdfIcon,
    ImageIcon,
    MoreVertIcon,
    AssignmentIcon,
    LockIcon,
    LockOpenIcon,
    EventAvailableIcon,
    EventBusyIcon,
    EventNoteIcon,
    InsertDriveFileIcon,
    HistoryIcon,
    SyncIcon,
    MemberIcon,
    UpgradeIcon,
    DowngradeIcon,
    AcceptIcon,
    DenyIcon,
    ArrowBackIcon,
    ScheduleIcon,
    TimelapseIcon,
    SettingsIcon,
    ErrorRoundedIcon,
    AddIcon,
    SubdirectoryArrowRightIcon,
    WarningIcon,
    ArchiveIcon,
    UnarchiveIcon,
    MaskIcon,
};
